import React from 'react';
import Header from "../components/Header/Header";
import Banner from "../components/Banner/Banner";
import Footer from "../components/Footer/Footer";
import BigText from "../components/UI/BigText/BigText";
import {lan} from "../constants/lan";
import Block from "../components/UI/Block/Block";
import FlexBlock from "../components/UI/FlexBlock/FlexBlock";
const Bibliography = () => {
    return (
        <div>
            <Header/>
            <Block>
                <BigText>{lan.bibliographyHeaderText}</BigText>
                <FlexBlock style={{flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", textAlign: "justify"}}>
                    <p>[1] Liakopoulou, Maria, “Teachers’ professional competence: Which qualities, attitudes, skills, and knowledge contribute to their effectiveness?” <i>International Journal of the Humanities and Social Sciences</i>, vol. 1, pp. 66-78, 2011. Available: https://www.ijhssnet.com/journals/Vol_1_No_21_Special_Issue_December_2011/8.pdf.</p>
                    <p>[2] V. S. Kulishov, Y. A. Skyba, A. B. Yermolenko, S. S. Shevchuk, and T. P. Shchypska, “Development of the scientific and methodological competencies of teachers in vocational education institutions,” <i>Propósitos y Representaciones</i>, vol. 9(SPE2), e994, 2021. DOI: http://dx.doi.org/10.20511/pyr2021.v9nSPE2.994.</p>
                    <p>[3] F.K. Karimi, “Didactic competencies of teaching staff at universities in Kenya,” <i>International Journal of Higher Education</i>, vol. 3, no. 2, pp. 28-37, 2014. DOI: 10.5430/ijhe.v3n2p28.</p>
                    <p>[4] Степанець, І. О. (2020). Monitoring and diagnostics of the quality of scientific and methodological work in the context of competence approach in higher pedagogical education. Scientific Notes of the Pedagogical Department, (46), 43-52. https://doi.org/10.26565/2074-8167-2020-46-06.</p>
                    <p>[5] N. Novianti and I. Nurlaelawati, “Pedagogical competence development of university teachers with noneducation background: the case of a large university of education in Indonesia,” Indonesian Journal of Education, vol. 11, no. 2, pp. 169-177, 2019. doi: 10.17509/ije.v11i2.15711</p>
                    <p>[6] Simonović, N. (2021). Teachers’ Key Competencies for Innovative Teaching. International Journal of Cognitive Research in Science, Engineering and Education (IJCRSEE), 9(3), 331–345. https://doi.org/10.23947/2334-8496-2021-9-3-331-345</p>
                    <p>[7] Bertschy F, Künzli C, Lehmann M. Teachers’ Competencies for the Implementation of Educational Offers in the Field of Education for Sustainable Development. Sustainability. 2013; 5(12):5067-5080. https://doi.org/10.3390/su5125067</p>
                    <p>[8] Esteve-Mon, F. M., Llopis, M. Ángeles, & Adell-Segura, J. (2020). Digital Competence and Computational Thinking of Student Teachers. International Journal of Emerging Technologies in Learning (iJET), 15(02), pp. 29–41. https://doi.org/10.3991/ijet.v15i02.11588</p>
                    <p>[9] MORENO-MURCIA, Juan Antonio, SILVEIRA TORREGROSA, Yolanda, BELANDO PEDREÑO, and Noelia, “Questionnaire to evaluate teaching competencies in university environments; Evaluation of university teaching competencies,” Journal of New Approaches in Educational Research, [S.l.], vol. 4, no. 1, pp. 54-61, Jan. 2015. ISSN 2254-7339. Available: https://naerjournal.ua.es/article/view/106 [Accessed: Jun. 29, 2023.] DOI: http://dx.doi.org/10.7821/naer.2015.1.106</p>
                    <p>[10] O.A. Shamigulova, G. I. Kalimullina, and V. M. Yangirova, “Diagnostics of teachers’ methodological competencies,” in Social and Cultural Transformations in the Context of Modern Globalism (SCTCMG), D. Bataev, S. A. Gapurov, A. D. Osmaev, V. K. Akaev, L. M. Idigova, M. R. Ovhadov, A. R. Salgiriev, and M. M. Betilmerzaeva, Eds. European Proceedings of Social and Behavioral Sciences. European Publisher, 2022, pp. 553-560. https://doi.org/10.15405/epsbs.2022.11.76</p>
                    <p>[11] Moreira, M., Arcas, B. R., Sánchez, T. G., García, R. B., Melero, M. R., Cunha, N. B., Viana, M. A., & Almeida, M. (2023). Teachers' pedagogical competences in higher education: A systematic literature review. Journal of University Teaching & Learning Practice, 20(1), 90-123. https://doi.org/10.53761/1.20.01.07</p>
                    <p>[12] A. C. Thoron, B. E. Myers, A. Harder, N. Stedman and T. G. Roberts, "An analysis of teaching competencies of junior faculty with different levels of graduate teaching assistant experiences", J. Natural Resources Life Sci. Educ., vol. 41, pp. 1-6, 2012</p>
                    <p>[13] Abdullayeva G.S. Development of Methodological Competence of University Teachers in the Context of Inclusive Education // International Journal of Social Science Research and Review, 5(5), 34-39 DOI: https://doi.org/10.47814/ijssrr.v5i5.295</p>
                    <p>[14] Hammer, T., Lewis, A.L. Which competencies should be fostered in education for sustainable development at higher education institutions? Findings from the evaluation of the study programs at the University of Bern, Switzerland. Discov Sustain 4, 19 (2023). https://doi.org/10.1007/s43621-023-00134-w</p>
                    <p>[15] Agapov, A.M., Mysina, T.Yu., 2022 The Relationship of Subject-Methodological Skills, Analytical and Communicative Competencies of Pedagogical Students / S. Vachkova, & S. S. Chiang (Eds.) // Education and City: Quality Education for Modern Cities, vol 3. European Proceedings of Educational Sciences (pp. 58-68). European Publisher. https://doi.org/10.15405/epes.22043.6</p>
                    <p>[16] Romanyuk, S., Rusnak, I., Dolynskiy, I., Maftyn, L., Onyshkiv, Z. (2022) Competence-Based Readiness of Future Teachers to Professional Activity in Educational Institutions // Journal of Curriculum and Teaching. 11(2):42. https://doi.org/10.5430/jct.v11n2p42</p>
                    <p>[17] Konovalov, A. (2023) Methodical Competence Deficits of Vocational Training Teachers// https://www.researchgate.net/publication/372151049_Methodical_Competence_Deficits_of_Vocational_Training_Teachers. DOI:10.26907/esd.18.2.07</p>
                    <p>[18] Lemovska L. (2022) Methodological competence development of preschool teachers in the system of continuous education// Polonia University Scientific Journal. 53(4):9-20. DOI:10.23856/5301 http://pnap.ap.edu.pl/index.php/pnap/article/view/937/894</p>
                    <p>[19] Berseneva O. (2017) Professional Training Tasks As an Instrument Monitoring of the Methodical Competence of Future Teachers. Standards and Monitoring in Education 5(2):9-16 https://doi.org/10.12737/25137 https://www.researchgate.net/publication/316346290_Professional_Training_Tasks_As_an_Instrument_Monitoring_of_the_Methodical_Competence_of_Future_Teachers</p>
                    <p>[20] Mata L. (2011) Experimental research regarding the development of methodological competences in beginning teachers // Procedia - Social and Behavioral Sciences, Volume 29. Pp. 1895-1904,https://doi.org/10.1016/j.sbspro.2011.11.439</p>
                    <p>[21] Есимгалиева Т.М., Косшыгулова А.С. (2022) Проблемы формирования профессиональной компетентности будущих педагогов // ВЕСТНИК КазНПУ им. Абая, серия «Педагогические науки». – № 1(73). – С.150-157. https://doi.org/10.51889/2022-1.1728-5496.15</p>
                    <p>[22] Rahman, M. H. (2014) Professional Competence, Pedagogical Competence and the Performance of Junior High School of Science Teachers. // Journal of Education and Practice. – 5. – 75-80. https://core.ac.uk/download/pdf/234635552.pdf</p>
                    <p>[23] Ilanlou, Maryam & Zand, Maryam (2010) Professional Competencies of Teachers and the Qualitative Evaluation. Procedia – Social and Behavioral Sciences. 29. – 1143–1150. – https://doi.org/10.1016/j.sbspro.2011.11.348</p>
                    <p>[24] Matiash, O., & Mykhailenko, L. (2020) Opportunities for method competence development of mathematics teachers : The role of participation in competitions with colleagues. Universal Journal of Educational Research, 8(3), 747-754, 2020. https://doi.org/10.13189/ujer.2020.080303</p>
                    <p>[25] Agapov, A.M., Mysina, T.Yu., (2022) The Relationship of Subject-Methodological Skills, Analytical and Communicative Competencies of Pedagogical Students / S. Vachkova, & S. S. Chiang (Eds.) // Education and City: Quality Education for Modern Cities, vol 3. European Proceedings of Educational Sciences. Pp. 58-68. European Publisher. https://doi.org/10.15405/epes.22043.6</p>
                    <p>[26] Biloshchytskyi, A.; Omirbayev, S.; Mukhatayev, A.; Faizullin, A.; Toxanov, S.; Kassenov, K. (2020) Research on the formation level of methodological competence of it disciplines teachers // ATIT 2020 - Proceedings: 2020 2nd IEEE International Conference on Advanced Trends in Information Theory. 2020. https://doi.org/ 10.1109/ATIT50783.2020.9349337</p>
                    <p>[27] Sharifbaeva K., Niyazova G., Abdurazzakova D. (2022) Formation of methodical competence of special subjects teachers in technical universities // AIP Conference Proceedings 2432(1):050043https://doi.org/ 10.1063/5.0089618</p>
                    <p>[28] Vlasenko T. (2019) A Methodological Approach to Evaluating the Level of Staff Competence for Implementation of Strategic Changes // https://www.researchgate.net/publication/337437081_A_Methodological_Approach_to_Evaluating_ the_Level_of_Staff_Competence_for_Implementation_of_Strategic_Changes DOI: 10.32983/2222-4459-2019-9-339-344</p>
                    <p>[29] Shevchuk S., Kulishov V., Yermolenko A. Innovative principles of development of methodical competence of modern teacher of vocational education //  https://www.researchgate.net/publication/362973677_Innovative_principles_of_development_of_m ethodical_competence_of_modern_teacher_of_vocational_education</p>
                    <p>[30] Selvi, Kıymet. Teachers’ Competencies. Cultura. International Journal of Philosophy of Culture and Axiology. 7. – 167–175. – 2010. – https://doi.org/10.5840/cultura 20107133</p>
                    <p>[31] Omirbayev S. Structural model of the system of development of methodological competence of IT-Discipline teachers on the basis of continuing education / S. Omirbayev, A. Mukhatayev, A. Biloshchytskyi, S. Toxanov, A. Faizullin, S. Biloshchytska // Scientific Journal of Astana IT University. — 2022. — No 12. — P. 122-138 https://doi.org/10.37943/12TYUY5628</p>
                    <p>[32] Hammer T., Lewis A. (2023) Which competencies should be fostered in education for sustainable development at higher education institutions? Findings from the evaluation of the study programs at the University of Bern, Switzerland // Discover Sustainability, 4(1), 19. - https://doi.org/10.1007/s43621-023-00134-w</p>
                    <p>[33] Palamar S., Nazarenko L., Vaskivska H., Nezhyva L., Golota N., Zhelanova V. (2020) Formation of  educational and cognitive competency of junior specialists in the process of self-educational activity: experiential aspect// Wiadomości Lekarskie, Vol. LXXIII, Issue 12 Part 1. - https://doi.org/10.36740/WLek202012118</p>
                    <p>[34] Alipichev, A. Y., Sergeeva, N. A., & Takanova, O. (2022). Development of the Methodological Competence of Students in the Distance Learning Environment. ARPHA Proceedings, 5, 29-45.https://doi.org/10.3897/ap.5.e0029</p>
                    <p>[35] Nematillayevna, J. M. (2021). Formation of methodological competencies of future mathematics teachers in the field of quality assessment of education. Asian Journal Of Multidimensional Research, 10(6), 67-71. https://doi.org/10.47814/ijssrr.v5i5.295</p>
                    <p>[36] Dyganova, E. A., & Yavgildina, Z. M. (2020). Development of student musician's methodological competence in practice-oriented university environment. Utopía y praxis latinoamericana: revista internacional de filosofía iberoamericana y teoría social, (5), 113-125. DOI:http://doi.org/10.5281/zenodo.39842</p>
                    <p>[37] Iskandjanova, F. K. (2022). The mechanism of methodological improvement of professional and pedagogical competence of future medical teachers. Central Asian Journal of Medicine, 1, 5-10 // https://scholar.google.com/scholar?hl=ru&as_sdt=0%2C5&q=Iskandjanova%2C+F.+K.+%282022%29.+The+mechanism+of+methodological+improvement+of+professional+and+pedagogical+competence+of+future+medical+teachers.+Central+Asian+Journal+of+Medicine%2C+1%2C+5-10&btnG=</p>
                    <p>[38] Ololube, N. P. (2006, March). An examination of professional and non-professional teacher’s classroom methodological competencies. In Proceedings of The International Applied Business Research (IABR) and College Teaching & Learning (TLC) Conferences (pp. 1-13)// https://d1wqtxts1xzle7.cloudfront.net/47404973/An_Examination_Of_Professional_And_Non-P20160721-14116-znd63r-libre.pdf?1469112667=&response-content-disposition=inline%3B+filename%3DAn_Examination_Of_Professional_And_Non_P.pdf&Expires=1699570273&Signature=RDPQ3PtshKQa8lYK42QmZ87-Q6eMF2083h5SZO9p2t7P-nBOYsBzyWg7Bwmd~oBZ5vikR1xpSZC3qaxKZOKOi25BUUuMuFcd4sYtFR4kfxtHYGtgKoLKxv52R-xO7n3rhV6AN7tguGE2uVGtr6lCXpNUEZ4AQ77sfpjJv16bhyB-~ewY7zIEOatMVZIKJTgbCAACqW~UxYrgWceLlhwDh0Ut1obnkKswY0Z2zRPyByTCjax3UjpcM2ghjFNhRShBTK1c3UV59N~VVJ22yYizSVjU3ssHKViNks-6w~8mx333SMsMKYFC4jmBA4cKR2CVgFkQTPcVM6OW6b5i4n8JYQ__&Key-Pair-Id=APKAJLOHF5GGSLRBV4ZA</p>
                    <p>[39] Sabljić, J. (2014). Ways of developing methodological competencies of literature students. JoLaCE, 2(2), 65.// https://d1wqtxts1xzle7.cloudfront.net/45035133/Change_in_Beliefs_on_Language_Learning_of_BA_Students-libre.pdf?1461482092=&response-content-disposition=inline%3B+filename%3DChange_in_Beliefs_on_Language_Learning_o.pdf&Expires=1699569920&Signature=ckDAB~Za6ev5q3irvi7Ktm4HOSZ0KX9DiRjCRQQDPm~F2rTO3Pxcob14qhoY8bg1dcW1UTgz43hFh7MZxde8h26nbW1W4LUPN3pfhFGQ4H9BwrSSMKN292N~f8ZJF2vZELj9~gfz5Kit7o6BDgAAdZCQj9u05yTFoD0CxDjmgvki6wJTp3v~FlzIDSX9M4VezIbPtj884xjKZ~csOMXjtlmbjQfadr0UmzQit9ssQWKwNBgQuQxzsnc6JTanAiSVXev1Vhe0PSzVYBCMXUzAUdHy3FUyCrwuTPnwhpGrv7T~UuOUstbSrLoxlrE2EAv8pNiV7QUGLpPXAdOYyM~l1Q__&Key-Pair-Id=APKAJLOHF5GGSLRBV4ZA#page=65</p>
                    <p>[40] Tolochko, S., & Ridey, N. (2017). Theoretical substantiation of the necessity of formation of scientific-methodological competence of teachers in the system of postgraduate pedagogical education. ScienceRise: Pedagogical Education, (8), 14-19. DOI: 10.15587/2519-4984.2017.108974</p>
                    <p>[41] Vălcan, T. D. (2016). An Example of the Training a Methodological Competences in Mathematical Analysis. European Proceedings of Social and Behavioural Sciences.// https://www.europeanproceedings.com/files/data/article/49/1507/article_49_1507_pdf_100.pdf</p>
                    <p>[42] Shevchuk, S., Kulishov, V., & Shchypska, T. (2021). Vectors of development of educational and methodological competence of the modern teacher in the conditions of modernization of vocational education of Ukraine. Journal of International Legal Communication, 2, 63-74. . https://doi.org/10.32612/uw.27201643.2021.2</p>
                    <p>[43] Weinert, F. E. (1999, April). Concepts of competence. OFS. // https://scholar.google.com/scholar?hl=ru&as_sdt=0%2C5&q=Weinert%2C+F.+E.+%281999%2C+April%29.+Concepts+of+competence.+OFS.&btnG=</p>
                    <p>[44] Akhmetzhanova, G., Sarbasova, K., Shraimanova, G., Tazhigulova, G., & Kaltayeva, G. (2019). Pedagogical conditions for the formation of informational and methodological competence of learners. Opción: Revista de Ciencias Humanas y Sociales, (24), 1361-1375.// https://scholar.google.com/scholar?hl=ru&as_sdt=0%2C5&q=Akhmetzhanova%2C+G.%2C+Sarbasova%2C+K.%2C+Shraimanova%2C+G.%2C+Tazhigulova%2C+G.%2C+%26+Kaltayeva%2C+G.+%282019%29.+Pedagogical+conditions+for+the+formation+of+informational+and+methodological+competence+of+learners.+Opci%C3%B3n%3A+Revista+de+Ciencias+Humanas+y+Sociales%2C+%2824%29%2C+1361-1375.&btnG=</p>
                    <p>[45] Koknova, T. (2018). Development of Linguistic and Methodological Competencies of Foreign Language Teachers as a Current Pedagogical Problem. // http://dspace.luguniv.edu.ua/xmlui/bitstream/handle/123456789/4469/41.pdf?sequence=1&isAllowed=y</p>
                    <p>[46] Zhdanko, T. A., Gershpigel, S. V., Gurinovich, A. V., & Mikhailova, M. M. (2021). Diagnostics of Subject and Methodological Competencies of Teachers. Sovremennye problemy nauki i obrazovaniya, 2. DOI:10.17513/spno.30576</p>
                    <p>[47] АТИКОЛ, Б., & НИЯЗОВА, Г. (2020). Digital technologies in the formation of methodological competence of future teachers. Вестник Академии Педагогических Наук Казахстана, (3), 73-81. https://www.elibrary.ru/item.asp?id=44872696</p>
                    <p>[48] Malika, K. (2022). CURRENT PROBLEMS OF THE DEVELOPMENT OF METHODOLOGICAL COMPETENCE OF TEACHERS IN THE METHODOLOGY OF TEACHING HUMAN ANATOMY AND PHYSIOLOGY ON THE BASIS OF MULTIMEDIA TECHNOLOGIES IN PEDAGOGICAL UNIVERSITIES. International Journal of Pedagogics, 2(10), 33-43.https://doi.org/10.37547/ijp/Volume02Issue10-06</p>
                    <p>[49] Genkal, S. E., & Chernyakova, Z. Y. (2016). Methodological competence as the basis of fundamentalization of professional training of future teachers. Science and Education a New Dimension. Pedagogy and Psychology, IV (48), 102, 18-22. // https://seanewdim.com/wp-content/uploads/2021/03/Methodological-competence-as-the-basis-of-fundamentalization-of-professional-training-of-future-teachers-S.-E.-Genkal-Zh.-Yu.-Chernyakova.pdf</p>
                    <p>[50] Tejedor, G., Segalàs, J., Barrón, Á., Fernández-Morilla, M., Fuertes, M. T., Ruiz-Morales, J., ... & Hernández, À. (2019). Didactic strategies to promote competencies in sustainability. Sustainability, 11(7), 2086. // https://www.mdpi.com/2071-1050/11/7/2086</p>
                </FlexBlock>

            </Block>
            <Footer/>
        </div>
    );
};

export default Bibliography;