export const clrs = {
    whiter: '#f0f3f5',
    whiter2: '#e8e8e8',
    blackBlue: '#153C6B',
    white: '#ffffff',
    black: '#000000',
    blacker: '#2d2d2d',
    red: '#E83151',
    green: '#0B7A75',
    silver: '#65676D',
    yellow: '#E5B600',
    skin: '#FCE5D3',
}