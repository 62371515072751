import React from 'react';
import classes from './Divider.module.css';

const HorizontalDivider = () => {
    return (
        <div className={classes.horizontal}>

        </div>
    );
};

export default HorizontalDivider;