const ru = {
    projectDesc: "Данный сайт разработан в рамках реализации проекта ИРН АР14870918 «Создание системы развития методической компетентности преподавателей IT-дисциплин на основе непрерывности образования».\n",
    projectTopic: "Наименование темы проекта",
    projectTopicFull: "Развитие методической компетентности преподавателей IT-дисциплин посредством создания информационной системы непрерывного образования",
    projectObjectives: "Задачи проекта",
    obj1: "изучить факторы, влияющие на качество преподавания IT-дисциплин",
    obj2: "разработать концепцию развития методической компетентности преподавателей IT-дисциплин на основе анализа продвинутого отечественного и международного опыта системы подготовки будущих кадров и повышения квалификации педагогов",
    obj3: "разработать и внедрить систему прогнозирования и непрерывной поддержки развития методической компетентности преподавателей с учетом особенностей преподаваемых IT-дисциплин",
    obj4: "разработать систему курсовой и посткурсовой поддержки преподавателей IT-дисциплин, включающая программное и учебно-методическое обеспечение",
    obj5: "разработать и внедрить уровневые стандарты на основе профессионального стандарта «Педагог» и образовательную программу курсов повышения квалификации по развитию методической компетентности преподавателей IT-дисциплин",
    projectAim: "Цель проекта:",
    projectAimFull: "Разработка и внедрение системы повышения методической компетентности, позволяющей улучшить дидактическую подготовку преподавателей IT-дисциплин через эффективное управление их профессиональным развитием.",
    muhataevAidos: "Мухатаев Айдос Агдарбекович",
    muhataevAidosPos: "Научный руководитель проекта",
    muhataevAidosDesc: "Кандидат педагогических наук",
    muhataevAidosDesc2: "Ассоциированный профессор Astana IT University",
    omirbayevSerik: "Омирбаев Серик Мауленович",
    omirbayevSerikPos: "Ведущий научный сотрудник",
    omirbayevSerikDesc: "Доктор экономических наук, профессор",
    omirbayevSerikDesc2: "И.о. Ректора Astana IT University",
    beloshitski: "Белощицкий Андрей Александрович\n",
    beloshitskiPos: "Ведущий научный сотрудник\n",
    beloshitskiDesc: "Доктор технических наук, профессор",
    beloshitskiDesc2: "Проректор по науке и инновациям Astana IT University",
    kasenov: "Касенов Ханат Нурбикович\n",
    kasenovPos: "Научный сотрудник\n",
    kasenovDesc: "PhD, Директор Департамента обеспечения качества Astana IT University",
    kasenovDesc2: "",
    kuangalieva: "Куангалиева Турсынзада Куангалиевна",
    kuangalievaPos: "Младший научный сотрудник\n",
    kuangalievaDesc: "Кандидат экономических наук, ассоциированный профессор",
    kuangalievaDesc2: "Проректор по академической деятельности Академии физической культуры и массового спорта",
    sapar: "Токсанов Сапар Нурахметович\n",
    saparPos: "Научный сотрудник\n",
    saparDesc: "PhD, Директор Центра компетенций и совершенства Astana IT University",
    saparDesc2: "",
    faizullin: "Файзуллин Адиль Рамазанович\n",
    faizullinPos: "Научный сотрудник\n",
    faizullinDesc: "PhD Candidate",
    faizullinDesc2: "Директор Департамента стратегии и корпоративного управления Astana IT University",
    omarova: "Омарова Сафура Караулбековна\n",
    omarovaPos: "Младший научный сотрудник\n",
    omarovaDesc: "PhD student",
    omarovaDesc2: "Евразийский национальный университет имени Л.Н. Гумилева",
    aboutUs: "О нас",
    team: "Команда",
    publications: "Публикации",
    contacts: "Контакты",
    bibliography: "Библиография",
    bibliographyHeaderText: "Литература, посвященная развитию методической компетентности профессорско-преподавательского состава",
    levelCompetence: "Хотите узнать уровень вашей компетентности?",
    projectTeam: "Команда проекта",
    noAccount: "У меня нет учетной записи",
    yesAccount: "У меня есть учетная запись",
    login: "Войти",
    log_in: "Вход",
    register: "Регистрация",
    dashboard: "Панель управления",
    coursesAll: "Все курсы",
    coursesMy: "Мои курсы",
    postCoursesMy: "Мои пост-курсы",
    coursesPre: "Предварительные курсы",
    competenceMap: "Карта компетенций",
    knowYourLevelCompetence: "Узнать свой уровень методической компетентности",
    takeAnketa: "Пройти анкетирование",
    myTasks: "Мои задачи",
    chat: "Чат",
    chats: "Чаты",
    calendar: "Календарь",
    setting: "Настройки",
    logout: "Выйти",
    error: "Ошибка",
    problem: "Извините, у нас возникли некоторые проблемы",
    back: "Назад",
    taskDone: "Задача выполнена",
    lessons: "Уроки",
    sections: "Разделы",
    section: "Раздел",
    progress: "Прогресс",
    students: "Студенты",
    posts: "Публикации",
    tests: "Тесты",
    tasks: "Задачи",
    addLesson: "Добавить урок",
    addFile: "Добавить файл",
    cabinet: "Кабинет",
    aboutUs_forecasting: "Система прогнозирования и поддержки развития",
    aboutUs_concept: "Концепция развития методологической компетенции",
    aboutUs_rise: "Профессиональные курсы повышения квалификации для учителей",
    aboutUs_course: "Система курсового и посткурсового сопровождения",
    aboutUs_education: "Развитие IT-образования",
    aboutUs_practice: "Практическое обучение",
    aboutUs_innovation: "Инновационный подход",
    aboutUs_software: "Программное и учебно-методическое обеспечение",
    banner1: "Концепция развития методологических компетенций учителей IT-дисциплин",
    banner2: "Система курсового и посткурсового сопровождения для учителей IT-дисциплин",
    banner3: "Внедрение стандартов уровня на основе профессионального стандарта \"Учитель\"",
    banner4: "Система прогнозирования и непрерывной поддержки развития методологической компетенции учителей",
    education: "Образование",
    jobInformation: "Информация о работе",
    generalInformation: "Общая информация",
    email: "Электронная почта",
    birthDate: "Дата рождения",
    university: "Университет",
    universityName: "Название университета",
    universityJobName: "Место работы",
    graduationYear: "Год выпуска",
    specialization: "Специализация",
    admissionDate: "Дата поступления",
    degreeAwarded: "Полученная степень",
    degree: "Степень",
    position: "Позиция",
    rank: "Звание",
    disciplineNames: "Дисциплины",
    userNotFound: "Пользователь не найден",
    courseNotFound: "Курс не найден",
    chatNotFound: "Чат не найден",
    postCourseNotFound: "Пост-курс не найден",
    taskNotFound: "Задача не найдена",
    courseFORBIDDEN: "У вас нет доступа к этому курсу",
    profile: "Профиль",
    emailConfirm: "Подтверждение электронной почты",
    save: "Сохранить",
    send: "Отправить",
    create: "Создать",
    firstName: "Имя",
    secondName: "Фамилия",
    middleName: "Отчество",
    fillAllFields: "Заполните все поля",
    admin: "Админ",
    admins: "Админы",
    trainer: "Тренер",
    trainers: "Тренеры",
    role: "Роль",
    competenceBank: "Банк компетенций",
    createComponentBank: "Создать компонент",
    createAnketaBank: "Создать анкету",
    name: "Имя",
    nameEng: "Имя на английском",
    nameKz: "Имя на казахском",
    nameRu: "Имя на русском",
    descriptionEng: "Описание на английском",
    description: "Описание",
    descriptionKz: "Описание на казахском",
    descriptionRu: "Описание на русском",
    trainerUsername: "Имя пользователя тренера",
    course: "Курс",
    courses: "Курсы",
    postCourse: "Пост-курс",
    postCourses: "Пост-курсы",
    coursesList: "Список курсов",
    postCoursesList: "Список пост-курсов",
    createCourse: "Создать курс",
    createPostCourse: "Создать пост-курс",
    chooseComponent: "Выберите компонент",
    addSection: "Добавить раздел",
    addTask: "Добавить задачу",
    addQuestion: "Добавить вопрос",
    delete: "Удалить",
    deleteComponent: "Удалить компонент",
    deleteQuestionnaire: "Удалить анкету",
    sectionName: "Название раздела",
    question: "Вопрос",
    answer: "Ответ",
    questionType: "Тип вопроса",
    addCorrectAnswer: "Добавить правильный ответ",
    addAnswer: "Добавить ответ",
    addInCorrectAnswer: "Добавить неправильный ответ",
    createAnketaFormValidationError: "Неправильно составлена анкета",
    components: "Компоненты",
    component: "Компонент",
    questionnaires: "Анкеты",
    questionnaire: "Анкета",
    questionnaireName: "Название анкеты",
    errorDelete: "Не удалось удалить",
    password: "Пароль",
    confirmPassword: "Подтвердите пароль",
    username: "Имя пользователя",
    point: "Балл",
    status: "Статус",
    notStarted: "Не начато",
    notSubmitted: "Не отправлено",
    submitted: "Отправлено",
    submit: "Отправить",
    inProgress: "В процессе",
    notPassed: "Не пройдено",
    finished: "Завершено",
    passed: "Пройдено",
    actions: "Действия",
    view: "Просмотр",
    submission: "Отправка",
    activate: "Активировать",
    deactivate: "Деактивировать",
    pass: "Пройти",
    giveAnswersToAllQuestionsError: "Дайте ответы на все вопросы!",
    questionnairePassed: "Анкета пройдена",
    maxPoint: "Макс. балл",
    numQuestions: "Количество вопросов",
    user: "Пользователь",
    users: "Пользователи",
    userCreate: "Создать пользователя",
    fullName: "Полное имя",
    fullFilled: "Полностью заполнено",
    filled: "Заполнено",
    notFilled: "Не заполнено",
    file: "Файл",
    files: "Файлы",
    label: "Метка",
    certificate: "Сертификат",
    telNumber: "Номер телефона",
    task: "Задача",
    taskName: "Название задачи",
    deadline: "Срок выполнения",
    grade: "Оценка",
    graded: "Оценено",
    comment: "Комментарий",
    selectedStudent: "Выбранный студент",
    submittedFiles: "Отправленные файлы",
    trainingCourses: "Учебные курсы",
    changeProfileImage: "Изменить изображение профиля",
    profileImage: "Изображение профиля",
    profileImageHasChanged: "Изображение профиля было изменено!",
    applications: "Заявки",
    application: "Заявка",
    createApplication: "Создать заявку",
    applicationList: "Список заявок",
    confirmationMessageIsSent: "Сообщение с подтверждением отправлено",
    search: "Поиск",
    message: "Сообщение",
    title: "Заголовок",
    body: "Содержание",
    changeStatus: "Изменить статус",
    statusChanged: "Статус изменен!",
    approved: "Одобрено",
    notApproved: "Не одобрено",
    underConsideration: "На рассмотрении",
    awaiting: "Ожидает",
    littleCourse: 'курс',
    littlePostCourse: 'пост-курс',
    littleTrainingCourse: 'тренировка',
    applicationSuccessCreated: "Заявка успешно создана!",
    userSuccessCreated: "Пользователь успешно создан!",
    applicationErrorCreated: "Ошибка при создании заявки!",
    userErrorCreated: "Ошибка при создании пользователя!",
    roleChanged: "Роль изменена!",
    roleChangeFail: "Не удалось изменить роль!",
    roleName: "Название роли",
    analytics: "Аналитика",
    totalNumberOfUsers: "Общее количество пользователей",
    totalNumberOfStudents: "Общее количество студентов",
    numOfPassedUsersByQuestionnaires: "Количество пользователей, прошедших по анкетам",
    empty: "Пусто",
    passedStudents: "Студенты, которые прошли",
    notPassedStudents: "Студенты, которые не прошли",
    recommendation: "Рекомендация",
    weRecommend: "Рекомендуем пройти курс",
    yourResult: "Ваш результат",
    yourLevel: "Ваш уровень",
    yourLevelCompetence: "Ваш уровень методической компетентности",
    lowLevel: "Низкий",
    acceptableLevel: "Допустимый",
    averageLevel: "Средний",
    advancedLevel: "Продвинутый",
    expertLevel: "Экспертный",

    zeroLevel: "Нулевой",
    situationalLevel: "Ситуативный",
    developingLevel: "Развивающийся",

    addToMyPostCourses: "Добавить в мои пост-курсы"
}

const eng = {
    projectDesc: "This site was developed as part of the implementation of the project IRN AR14870918 “Creating a system for developing the methodological competence of teachers of IT disciplines based on continuous education.”",
    projectTopic: "Project Title",
    projectTopicFull: "Development of Methodological Competence for IT Discipline Instructors through the Creation of a Continuous Education Information System",

    projectObjectives: "Project Tasks",
    obj1: "Study the factors influencing the quality of teaching IT disciplines.",
    obj2: "Develop a concept for the development of methodological competence for IT discipline instructors based on an analysis of advanced domestic and international experience in preparing future professionals and enhancing the qualifications of educators.",
    obj3: "Develop and implement a system for forecasting and continuous support of the development of methodological competence for instructors, taking into account the specific characteristics of the taught IT disciplines.",
    obj4: "Develop a system of course and post-course support for IT discipline instructors, including software and educational materials.",
    obj5: "Develop and implement competency standards based on the professional standard \"Educator\" and an educational program for advanced courses in the development of methodological competence for IT discipline instructors.",
    projectAim: "Goal of the project:",
    projectAimFull: "Develop and implement a system for enhancing the methodological competence of IT discipline instructors, allowing for improved didactic preparation through effective management of their professional development",

    muhataevAidos: "Mukhataev Aidos Agdarbekovich",
    muhataevAidosPos: "Scientific supervisor of the project",
    muhataevAidosDesc: "Candidate of Pedagogical Sciences",
    muhataevAidosDesc2: "Associate Professor of Astana IT University",
    omirbayevSerik: "Omirbayev Serik Maulenovich",
    omirbayevSerikPos: "Leading Researcher",
    omirbayevSerikDesc: "Doctor of Economic Sciences, Professor",
    omirbayevSerikDesc2: "Acting Rector of Astana IT University",
    beloshitski: "Biloshchytskyi Andrii Aleksandrovich",
    beloshitskiPos: "Leading Researcher",
    beloshitskiDesc: "Doctor of Technical Sciences, Professor",
    beloshitskiDesc2: "Vice-rector for science and innovations of Astana IT University",
    kasenov: "Kassenov Khanat Nurbikovich",
    kasenovPos: "Researcher",
    kasenovDesc: "PhD, Director of the Department of Quality Assurance of Astana IT University\n",
    kasenovDesc2: "",
    kuangalieva: "Kuangaliyeva Tursynzada Kuangalievna",
    kuangalievaPos: "Junior researcher",
    kuangalievaDesc: "Candidate of Economic Sciences, Associate Professor\n",
    kuangalievaDesc2: "Vice-Rector for Academic Affairs of Academy of Physical Education and Mass Sports",
    sapar: "Toxanov Sapar Nurakhmetovich",
    saparPos: "Researcher",
    saparDesc: "PhD, Director of the Center of Competence and Excellence of Astana IT University",
    saparDesc2: "",
    faizullin: "Faizullin Adil Ramazanovich",
    faizullinPos: "Researcher",
    faizullinDesc: "PhD Candidate",
    faizullinDesc2: "Director of the Department of Strategy and Corporate Governance of Astana IT University",
    omarova: "Omarova Safura Karaulbekovna",
    omarovaPos: "Junior researcher",
    omarovaDesc: "Doctoral student",
    omarovaDesc2: "Gumilyov Eurasian National University",
    knowYourLevelCompetence: "Find out your level of methodological competence",
    takeAnketa: "Take the questionnaire",
    aboutUs: "About Us",
    team: "Team",
    publications: "Publications",
    contacts: "Contacts",
    bibliography: "Bibliography",
    bibliographyHeaderText: "Literature dedicated to the development of methodological competence of teaching staff",
    levelCompetence: "Do you want to know the level of your competence?",
    projectTeam: "Project Team",
    noAccount: "I don't have an account",
    yesAccount: "I have an account",
    login: "Login",
    log_in: "Log in",
    register: "Register",
    dashboard: "Dashboard",
    coursesAll: "All courses",
    coursesMy: "My courses",
    postCoursesMy: "My post-courses",
    coursesPre: "Pre-courses",
    competenceMap: "Competence Map",
    myTasks: "My Tasks",
    chat: "Chat",
    chats: "Chats",
    calendar: "Calendar",
    setting: "Settings",
    logout: "Logout",
    error: "Error",
    problem: "Sorry, we have some problems",
    back: "Back",
    taskDone: "Task Done",
    lessons: "Lessons",
    sections: "Sections",
    section: "Section",
    progress: "Progress",
    students: "Students",
    posts: "Posts",
    tests: "Tests",
    tasks: "Tasks",
    addLesson: "Add lesson",
    addFile: "Add file",
    cabinet: "Cabinet",
    aboutUs_forecasting: "Development forecasting and support system",
    aboutUs_concept: "Concept of methodological competence development",
    aboutUs_rise: "Professional development courses for teachers",
    aboutUs_course: "System of course and post-course support",
    aboutUs_education: "IT-education development",
    aboutUs_practice: "Practical training",
    aboutUs_innovation: "Innovative Approach",
    aboutUs_software: "Software and educational-methodical support",
    banner1: "The concept of development of methodological competencies of teachers of IT disciplines",
    banner2: "System of course and post-course support for teachers of IT disciplines",
    banner3: "Introduction of level standards on the basis of the professional standard \"Teacher\"",
    banner4: "The system of forecasting and continuous support for the development of methodological competence of teachers",
    education: "Education",
    jobInformation: "Job information",
    generalInformation: "General information",
    email: "Email",
    birthDate: "Birth date",
    university: "University",
    universityName: "University",
    universityJobName: "University",
    graduationYear: "Graduation year",
    specialization: "Specialization",
    admissionDate: "Admission date",
    degreeAwarded: "Degree awarded",
    degree: "Degree",
    position: "Position",
    rank: "Rank",
    disciplineNames: "Disciplines",
    userNotFound: "User not found",
    courseNotFound: "Course not found",
    chatNotFound: "Chat not found",
    postCourseNotFound: "Post-Course not found",
    taskNotFound: "Task not found",
    courseFORBIDDEN: "You do not have access to this course",
    profile: "Profile",
    emailConfirm: "Email confirmation",
    save: "Save",
    send: "Send",
    create: "Create",
    firstName: "First name",
    secondName: "Second name",
    middleName: "Middle name",
    fillAllFields: "Fill in all the fields",
    admin: "Admin",
    admins: "Admins",
    trainer: "Trainer",
    trainers: "Trainers",
    role: "Role",
    competenceBank: "Competence Bank",
    createComponentBank: "Create Component",
    createAnketaBank: "Create Questionnaire",
    name: "Name",
    nameEng: "Name in English",
    nameKz: "Name in Kazakh",
    nameRu: "Name in Russian",
    descriptionEng: "Description in English",
    description: "Description",
    descriptionKz: "Description in Kazakh",
    descriptionRu: "Description in Russian",
    trainerUsername: "Trainer username",
    course: "Course",
    courses: "Courses",
    postCourse: "Post-Course",
    postCourses: "Post-Courses",
    coursesList: "Courses list",
    postCoursesList: "Post-Courses list",
    createCourse: "Create course",
    createPostCourse: "Create post-course",
    chooseComponent: "Choose component",
    addSection: "Add Section",
    addTask: "Add Task",
    addQuestion: "Add Question",
    delete: "Delete",
    deleteComponent: "Delete component",
    deleteQuestionnaire: "Delete questionnaire",
    sectionName: "Section name",
    question: "Question",
    answer: "Answer",
    questionType: "Question type",
    addCorrectAnswer: "Add correct answer",
    addAnswer: "Add answer",
    addInCorrectAnswer: "Add incorrect answer",
    createAnketaFormValidationError: "Incorrectly formed questionnaire",
    components: "Components",
    component: "Component",
    questionnaires: "Questionnaires",
    questionnaire: "Questionnaire",
    questionnaireName: "Questionnaire name",
    errorDelete: "Deletion failed",
    password: "Password",
    confirmPassword: "Confirm password",
    username: "Username",
    point: "Point",
    status: "Status",
    notStarted: "Not started",
    notSubmitted: "Not Submitted",
    submitted: "Submitted",
    submit: "Submit",
    inProgress: "In progress",
    notPassed: "Not passed",
    finished: "Finished",
    passed: "Passed",
    actions: "Actions",
    view: "View",
    submission: "Submissions",
    activate: "Activate",
    deactivate: "Deactivate",
    pass: "Pass",
    giveAnswersToAllQuestionsError: "Give answers to all questions!",
    questionnairePassed: "Questionnaire passed",
    maxPoint: "Max. point",
    numQuestions: "Number of questions",
    user: "User",
    users: "Users",
    userCreate: "Create user",
    fullName: "Full name",
    fullFilled: "Full filled",
    filled: "Filled",
    notFilled: "Not filled",
    file: "File",
    files: "Files",
    label: "Label",
    certificate: "Certificate",
    telNumber: "Phone number",
    task: "Task",
    taskName: "Task name",
    deadline: "Deadline",
    grade: "Grade",
    graded: "Graded",
    comment: "Comment",
    selectedStudent: "Selected student",
    submittedFiles: "Submitted files",
    trainingCourses: "Training Courses",
    changeProfileImage: "Change profile image",
    profileImage: "Profile image",
    profileImageHasChanged: "Profile image has changed!",
    applications: "Applications",
    application: "Application",
    createApplication: "Create application",
    applicationList: "Application list",
    confirmationMessageIsSent: "Confirmation message has been sent",
    search: "Search",
    message: "Message",
    title: "Title",
    body: "Body",
    changeStatus: "Change status",
    statusChanged: "Status changed!",
    approved: "Approved",
    notApproved: "Not approved",
    underConsideration: "Under consideration",
    awaiting: "Awaiting",
    littleCourse: 'course',
    littlePostCourse: 'post-course',
    littleTrainingCourse: 'training',
    applicationSuccessCreated: "Application created!",
    userSuccessCreated: "User created!",
    applicationErrorCreated: "Application creation failed!",
    userErrorCreated: "User creation failed!",
    roleChanged: "Role changed!",
    roleChangeFail: "Role change failed!",
    roleName: "Role name",
    analytics: "Analytics",
    totalNumberOfUsers: "Total number of users",
    totalNumberOfStudents: "Total number of students",
    numOfPassedUsersByQuestionnaires: "Number of passed users by questionnaires",
    empty: "Empty",
    passedStudents: "Passed students",
    notPassedStudents: "Not passed students",
    recommendation: "Recommendation",
    weRecommend: "We recommend taking the course",
    yourResult: "Your result",
    yourLevel: "Your level",
    yourLevelCompetence: "Your level of methodological competence",
    lowLevel: "Low",
    acceptableLevel: "Acceptable",
    averageLevel: "Average",
    advancedLevel: "Advanced",
    expertLevel: "Expert",

    zeroLevel: "Zero",
    situationalLevel: "Situational",
    developingLevel: "Developing",

    addToMyPostCourses: "Add to my post-courses"
}

const kz = {
    projectDesc: "Бұл сайт ЖТН AR14870918 «Үздіксіз білім беру негізінде IT пәндері оқытушыларының әдістемелік құзыреттілігін дамыту жүйесін құру» жобасын жүзеге асыру аясында әзірленген.",
    projectTopic: "Жоба тақырыбының атауы",
    projectTopicFull: "Үздіксіз білім берудің ақпараттық жүйесін құру арқылы IT-пәндер оқытушыларының әдістемелік құзыреттілігін дамыту",

    projectObjectives: "Жобаның міндеттері",
    obj1: "IT-пәндерді оқыту сапасына әсер ететін факторларды зерделеу",
    obj2: "болашақ кадрларды даярлау және педагогтердің біліктілігін арттыру жүйесінің озық отандық және халықаралық тәжірибесін талдау негізінде IT-пәндер оқытушыларының әдістемелік құзыреттілігін дамыту тұжырымдамасын әзірлеу",
    obj3: "оқытылатын IT-пәндердің ерекшеліктерін ескере отырып, оқытушылардың әдістемелік құзыреттілігін дамытуды болжау және үздіксіз қолдау жүйесін әзірлеу және енгізу",
    obj4: "бағдарламалық және оқу-әдістемелік қамтамасыз етуді қамтитын it-пәндер оқытушыларын курстық және курстан кейінгі қолдау жүйесін әзірлеу",
    obj5: "\"Педагог\" кәсіби стандарты негізінде деңгейлік стандарттарды және IT-пәндер оқытушыларының әдістемелік құзыреттілігін дамыту бойынша біліктілікті арттыру курстарының білім беру бағдарламасын әзірлеу және енгізу",
    projectAim: "Жобаның мақсаты:",
    projectAimFull: "IT-пәндер оқытушыларының кәсіби дамуын тиімді басқару арқылы  дидактикалық дайындығын жақсартуға мүмкіндік беретін әдістемелік құзыреттілікті арттыру жүйесін әзірлеу және енгізу",

    muhataevAidos: "Мухатаев Айдос Агдарбекович",
    muhataevAidosPos: "Жобаның ғылыми жетекшісі",
    muhataevAidosDesc: "Педагогика ғылымдарының кандидаты",
    muhataevAidosDesc2: "Astana IT University қауымдастырылған профессоры",
    omirbayevSerik: "Омирбаев Серик Мауленович",
    omirbayevSerikPos: "Жетекші ғылыми қызметкер",
    omirbayevSerikDesc: "Экономика ғылымдарының докторы, профессор",
    omirbayevSerikDesc2: "Astana IT University Ректорының м.а.",
    beloshitski: "Белощицкий Андрей Александрович\n",
    beloshitskiPos: "Жетекші ғылыми қызметкер",
    beloshitskiDesc: "Техника ғылымдарының докторы, профессор",
    beloshitskiDesc2: "Astana IT University ғылым және инновациялар жөніндегі проректоры",
    kasenov: "Касенов Ханат Нурбикович\n",
    kasenovPos: "Ғылыми қызметкер",
    kasenovDesc: "PhD, Astana IT University сапаны қамтамасыз ету департаментінің директоры",
    kasenovDesc2: "",
    kuangalieva: "Куангалиева Турсынзада Куангалиевна",
    kuangalievaPos: "Кіші ғылыми қызметкер",
    kuangalievaDesc: "Экономика ғылымдарының кандидаты, қауымдастырылған профессор",
    kuangalievaDesc2: "Дене шынықтыру және бұқаралық спорт Академиясының академиялық қызмет жөніндегі проректоры",
    sapar: "Токсанов Сапар Нурахметович\n",
    saparPos: "Ғылыми қызметкер",
    saparDesc: "PhD, Astana IT University Құзыреттілік және жетілдіру орталығының директоры",
    saparDesc2: "",
    faizullin: "Файзуллин Адиль Рамазанович\n",
    faizullinPos: "Ғылыми қызметкер",
    faizullinDesc: "PhD Candidate",
    faizullinDesc2: "AITU стратегия және корпоративтік басқару департаментінің Директоры",
    omarova: "Омарова Сафура Караулбековна\n",
    omarovaPos: "Кіші ғылыми қызметкер",
    omarovaDesc: "PhD Student",
    omarovaDesc2: "Л.Н.Гумилев атындағы Еуразия ұлттық университеті",
    knowYourLevelCompetence: "Әдістемелік құзыреттілік деңгейіңізді анықтаңыз",
    takeAnketa: "Сауалнамаға қатысыңыз",
    aboutUs: "Біз туралы",
    team: "Топ",
    publications: "Жарияланымдар",
    contacts: "Байланыс",
    bibliography: "Библиография",
    bibliographyHeaderText: "Педагогикалық ұжымның әдістемелік құзыреттілігін дамытуға арналған әдебиеттер",
    levelCompetence: "Компетенция деңгейіңізді білгіңіз келе ме?",
    projectTeam: "Жоба командасы",
    noAccount: "Менің аккаунттым жоқ",
    yesAccount: "Менің аккаунттым бар",
    login: "Кіру",
    log_in: "Кіру",
    register: "Тіркелу",
    dashboard: "Бақылау тақтасы",
    coursesAll: "Барлық курстар",
    coursesMy: "Менің курстарым",
    postCoursesMy: "Менің пост-курстарым",
    coursesPre: "Алдын-ала курстар",
    competenceMap: "Компетенция картасы",
    myTasks: "Менің тапсырмаларым",
    chat: "Чат",
    chats: "Чаттар",
    calendar: "Күнтізбе",
    setting: "Параметрлер",
    logout: "Шығу",
    error: "Қате",
    problem: "Кешіріңіз, бізде кейбір проблемалар бар",
    back: "Артқа",
    taskDone: "Тапсырма орындалды",
    lessons: "Сабақтар",
    sections: "Бөлімдер",
    section: "Бөлім",
    progress: "Прогресс",
    students: "Оқушылар",
    posts: "Хабарламалар",
    tests: "Тесттер",
    tasks: "Тапсырмалар",
    addLesson: "Сабақ қосу",
    addFile: "Файл қосу",
    cabinet: "Кабинет",
    aboutUs_forecasting: "Өсу болжауы және қолдау жүйесі",
    aboutUs_concept: "Әдістемелік компетенцияның дамуының концепциясы",
    aboutUs_rise: "Мұғалімдерге арналған кәсіби даму курстары",
    aboutUs_course: "Курс пен пост-курс қолдау жүйесі",
    aboutUs_education: "IT-білімді дамыту",
    aboutUs_practice: "Практикалық оқу",
    aboutUs_innovation: "Инновационды тәсіл",
    aboutUs_software: "Бағдарламалық және оқу-әдістемелік қолдау",
    banner1: "IT дисциплиналарының мұғалімдерінің әдістемелік компетенцияларының даму концепциясы",
    banner2: "IT дисциплиналарының мұғалімдеріне арналған курс пен пост-курс қолдау жүйесі",
    banner3: "«Мұғалім» кәсіби стандартының негізінде деңгей стандарттарын енгізу",
    banner4: "Мұғалімдердің әдістемелік компетенциясының дамуына болжамдалу және толық қолдау жүйесі",
    education: "Білім",
    jobInformation: "Жұмыс туралы ақпарат",
    generalInformation: "Жалпы ақпарат",
    email: "Электрондық пошта",
    birthDate: "Туған күн",
    university: "Университет",
    universityName: "Университеттің атауы",
    universityJobName: "Университеттің жұмыс атауы",
    graduationYear: "Түсу жылы",
    specialization: "Мамандық",
    admissionDate: "Қабылдау күні",
    degreeAwarded: "Берілген дәреже",
    degree: "Дәреже",
    position: "Позиция",
    rank: "Ранг",
    disciplineNames: "Дисциплиналар",
    userNotFound: "Пайдаланушы табылмады",
    courseNotFound: "Курс табылмады",
    chatNotFound: "Чат табылмады",
    postCourseNotFound: "Пост-курс табылмады",
    taskNotFound: "Тапсырма табылмады",
    courseFORBIDDEN: "Бұл курсқа кіру құқығыңыз жоқ",
    profile: "Профиль",
    emailConfirm: "Электрондық поштаны растау",
    save: "Сақтау",
    send: "Жіберу",
    create: "Жасау",
    firstName: "Аты",
    secondName: "Тегі",
    middleName: "Әкесінің аты",
    fillAllFields: "Барлық өрістерді толтырыңыз",
    admin: "Администратор",
    admins: "Администраторлар",
    trainer: "Тренер",
    trainers: "Тренерлер",
    role: "Рөл",
    competenceBank: "Компетенция банкі",
    createComponentBank: "Компонентті жасау",
    createAnketaBank: "Анкетаны жасау",
    name: "Атауы",
    nameEng: "Ағылшынша атауы",
    nameKz: "Қазақша атауы",
    nameRu: "Орысша атауы",
    descriptionEng: "Ағылшынша сипаттама",
    description: "Сипаттама",
    descriptionKz: "Қазақша сипаттама",
    descriptionRu: "Орысша сипаттама",
    trainerUsername: "Тренердің пайдаланушы аты",
    course: "Курс",
    courses: "Курстар",
    postCourse: "Пост-курс",
    postCourses: "Пост-курстар",
    coursesList: "Курс тізімі",
    postCoursesList: "Пост-курс тізімі",
    createCourse: "Курсты жасау",
    createPostCourse: "Пост-курсты жасау",
    chooseComponent: "Компонентті таңдау",
    addSection: "Бөлім қосу",
    addTask: "Тапсырма қосу",
    addQuestion: "Сұрақ қосу",
    delete: "Жою",
    deleteComponent: "Компонентті жою",
    deleteQuestionnaire: "Сауалнаманы жою",
    sectionName: "Бөлімнің аты",
    question: "Сұрақ",
    answer: "Жауап",
    questionType: "Сұрақ түрі",
    addCorrectAnswer: "Дұрыс жауап қосу",
    addAnswer: "Жауап қосу",
    addInCorrectAnswer: "Дұрыс емес жауап қосу",
    createAnketaFormValidationError: "Сауалнама дұрыс құрылмаған",
    components: "Компоненттер",
    component: "Компонент",
    questionnaires: "Сауалнамалар",
    questionnaire: "Сауалнама",
    questionnaireName: "Сауалнаманың атауы",
    errorDelete: "Жою сәтсіз аяқталды",
    password: "Құпия сөз",
    confirmPassword: "Құпия сөзді растау",
    username: "Пайдаланушы аты",
    point: "Ұпай",
    status: "Күйі",
    notStarted: "Басталған жоқ",
    notSubmitted: "Жіберілмеген",
    submitted: "Жіберілді",
    submit: "Жіберу",
    inProgress: "Жүргізілуде",
    notPassed: "Өткізбеді",
    finished: "Аяқталды",
    passed: "Өткізді",
    actions: "Әрекеттер",
    view: "Көру",
    submission: "Тапсырмалар",
    activate: "Белсендіру",
    deactivate: "Өшіру",
    pass: "Өткізу",
    giveAnswersToAllQuestionsError: "Барлық сұрақтарға жауап беріңіз!",
    questionnairePassed: "Сауалнама өткізілді",
    maxPoint: "Макс. ұпай",
    numQuestions: "Сұрақтар саны",
    user: "Пайдаланушы",
    users: "Пайдаланушылар",
    userCreate: "Пайдаланушыны жасау",
    fullName: "Толық аты-жөні",
    fullFilled: "Толық толтырылды",
    filled: "Толтырылды",
    notFilled: "Толтырылмаған",
    file: "Файл",
    files: "Файлдар",
    label: "Белгі",
    certificate: "Сертификат",
    telNumber: "Телефон номері",
    task: "Тапсырма",
    taskName: "Тапсырманың атауы",
    deadline: "Аяқталу мерзімі",
    grade: "Баға",
    graded: "Бағаланды",
    comment: "Пікір",
    selectedStudent: "Таңдалған студент",
    submittedFiles: "Жіберілген файлдар",
    trainingCourses: "Оқу курстары",
    changeProfileImage: "Профиль суретін өзгерту",
    profileImage: "Профиль суреті",
    profileImageHasChanged: "Профиль суреті өзгертілді!",
    applications: "Өтініштер",
    application: "Өтініш",
    createApplication: "Өтініш жасау",
    applicationList: "Өтініштің тізімі",
    confirmationMessageIsSent: "Растау хабарламасы жіберілді",
    search: "Іздеу",
    message: "Хабар",
    title: "Тақырып",
    body: "Мәтін",
    changeStatus: "Күйді өзгерту",
    statusChanged: "Күй өзгертілді!",
    approved: "Расталды",
    notApproved: "Расталмаған",
    underConsideration: "Орындалуда",
    awaiting: "Күту",
    littleCourse: 'курс',
    littlePostCourse: 'пост-курс',
    littleTrainingCourse: 'оқу',
    applicationSuccessCreated: "Өтініш сәтті жасалды!",
    userSuccessCreated: "Пайдаланушы сәтті жасалды!",
    applicationErrorCreated: "Өтініш жасау сәтсіз аяқталды!",
    userErrorCreated: "Пайдаланушы жасау сәтсіз аяқталды!",
    roleChanged: "Рөл өзгертілді!",
    roleChangeFail: "Рөл өзгерту сәтсіз аяқталды!",
    roleName: "Рөл атауы",
    analytics: "Аналитика",
    totalNumberOfUsers: "Пайдаланушылардың жалпы саны",
    totalNumberOfStudents: "Оқушылардың жалпы саны",
    numOfPassedUsersByQuestionnaires: "Сауалнамалар бойынша өткен пайдаланушылар саны",
    empty: "Бос",
    passedStudents: "Өткен оқушылар",
    notPassedStudents: "Өткен емес оқушылар",
    recommendation: "Ұсыныс",
    weRecommend: "Курсты өтуді ұсынамыз",
    yourResult: "Сіздің нәтижеңіз",
    yourLevel: "Сіздің деңгейіңіз",
    yourLevelCompetence: "Әдістемелік құзыреттілік деңгейіңіз",
    lowLevel: "Төмен",
    acceptableLevel: "Қолайлы",
    averageLevel: "Орташа",
    advancedLevel: "Жетілдірілген",
    expertLevel: "Мамандық",

    zeroLevel: "Нөл",
    situationalLevel: "Ситуациялық",
    developingLevel: "Дамуда",

    addToMyPostCourses: "Пост-курстарыма қосу"
}


export const lan = localStorage.getItem('lan') === "ENG" ? eng : (localStorage.getItem('lan') === "РУС" ? ru : (localStorage.getItem('lan') === "ҚАЗ" ? kz : eng))